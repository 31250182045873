<template>

	<el-tabs v-model="activeName" @tab-click="handleClick" style="padding: 10px 0 0 15px;">
		<el-tab-pane label="库存列表" name="inventoryList">
			<div>
				<div class="container">
					<div class="handle-box">
						<el-input size="small" v-model="query.phone" clearable filterable placeholder="请输入号码"
							class="mr10 mt10" style="width: 160px;"></el-input>
						<el-select size="small" v-model="query.cardId" clearable filterable placeholder="请选择品牌"
							class="mr10" @change="getCardId" style="width: 140px;">
							<el-option v-for="item in cardTypeList" :key="item.label" :label="item.value"
								:value="item.label"></el-option>
						</el-select>
						<el-select size="small" v-model="query.setMealId" clearable filterable placeholder="请选择套餐"
							class="mr10" @change="getquery">
							<el-option v-for="item in setMealIdList" :key="item.name" :label="item.name"
								:value="item.id"></el-option>
						</el-select>
						<el-select size="small" v-model="query.standard" clearable filterable placeholder="请选择制式"
							class="mr10" style="width: 140px;" @change="getquery">
							<el-option v-for="item in standardList" :key="item.label" :label="item.label"
								:value="item.value"></el-option>
						</el-select>
						<el-select size="small" v-model="query.province" clearable filterable placeholder="请选择省份"
							class="mr10" style="width: 140px;" @change="getInventoryCity">
							<el-option v-for="item in provinceList" :key="item" :label="item" :value="item"></el-option>
						</el-select>
						<el-select size="small" v-model="query.city" clearable filterable placeholder="请选择城市"
							class="mr10" style="width: 140px;" @change="getData">
							<el-option v-for="item in cityList" :key="item" :label="item" :value="item"></el-option>
						</el-select>
						<el-input size="small" v-model="query.batchNum" clearable filterable placeholder="请输入批次号"
							class="mr10 mt10" style="width: 160px;"></el-input>
						<el-select size="small" v-model="query.batchOpen" clearable filterable placeholder="是否批开"
							class="mr10 mt10">
							<el-option v-for="item in batchOpenList" :key="item.label" :label="item.label"
								:value="item.value"></el-option>
						</el-select>
						<el-date-picker v-model="timeArr" type="daterange" unlink-panels range-separator="至"
							start-placeholder="开始日期" end-placeholder="结束日期" @change="getTime()" size="small"
							class="mt10" style="width: 240px;margin-right: 10px;"></el-date-picker>
						<el-button size="small" type="primary" :class="[theme]" icon="el-icon-search" @click="getData"
							style="margin-top: 10px;">搜索</el-button>
						<el-button size="small" type="primary" style="margin-top: 10px;" :class="[theme]"
							@click="outBound">出库</el-button>
						<el-button size="small" type="primary" style="margin-top: 10px;" :class="[theme]"
							@click="storeInventory">入库</el-button>
					</div>
					<div class="city-box">
						<div v-for="(item, index) in inventoryInfoList" :key="item.inventory_count"
							style="cursor: pointer;display: flex;flex-direction: row;justify-content: space-between;margin-bottom: 6px;">
							<div v-if="index<9">
								<span style="margin-right: 10px;" @click="tabProvince(item)"
									v-if="item.province">{{ item.province }}</span>
								<span style="margin-right: 10px;" v-else>{{ item.city }}</span>
								<span>
									{{ item.inventory_count }}
									<i :class="[theme3]" class="el-icon-shopping-cart-full"
										style="margin-top: 3px;"></i>
								</span>
							</div>
							<div v-else-if="showForm&&index>8">
								<span style="margin-right: 10px;" @click="tabProvince(item)"
									v-if="item.province">{{ item.province }}</span>
								<span style="margin-right: 10px;" v-else>{{ item.city }}</span>
								<span>
									{{ item.inventory_count }}
									<i :class="[theme3]" class="el-icon-shopping-cart-full"
										style="margin-top: 3px;"></i>
								</span>
							</div>
						</div>
					</div>
					<div style="clear: both;"></div>
					<div class="loyo-folding-btn loadMore" v-if="showForm == true" @click="showForm = !showForm">
						<a href="javascript:;" data-toggle="tooltip" data-placement="right" title=""
							data-original-title="展开 "><i class="el-icon-arrow-up"></i></a>
					</div>
					<div class="loyo-folding-btn close__Btn down2" v-else @click="showForm = !showForm">
						<a href="javascript:;" data-toggle="tooltip" data-placement="right" title=""
							data-original-title="收起"><i class="el-icon-arrow-down"></i></a>
					</div>
					<div style="width: 100%;height: 20px;clear: both;"></div>
					<el-row :gutter="20" type="flex" class="row-bg">
						<el-col :span="24">
							<el-table :data="tableData" border class="table" ref="multipleTable"
								header-cell-class-name="table-header" :row-style="{ height: '20px' }"
								:cell-style="{ padding: '6px' }" :header-cell-style="{ 'text-align': 'center' }"
								@selection-change="handleSelectionChange" :row-class-name="tableRowClassName"
								@row-click="showExpressDialog">
								<el-table-column type="selection" width="55" align="center"></el-table-column>
								<el-table-column prop="card" label="运营商" align="center"></el-table-column>
								<el-table-column prop="phone" label="号码" align="center" width="130"></el-table-column>
								<el-table-column prop="numSegment" label="号段" align="center"
									width="86"></el-table-column>
								<el-table-column prop="standard" label="制式" align="center" width="60"></el-table-column>
								<el-table-column prop="province" label="省份" align="center" width="80"></el-table-column>
								<el-table-column prop="city" label="地市" align="center"></el-table-column>
								<el-table-column prop="iccid" label="ICCID" align="center"></el-table-column>
								<el-table-column prop="imsi" label="IMSI" align="center"></el-table-column>
								<el-table-column prop="setMeal" label="套餐" align="center">
									<template #default="scope">
										<el-tooltip placement="top">
											<template #content>
												<div style="max-width: 300px; white-space: pre-wrap;"
													v-for="item in scope.row.setMealList" :key="item.min">
													{{item.min}}分钟{{item.price}}元
												</div>
											</template>
											<span>{{scope.row.setMeal}}</span>
										</el-tooltip>
									</template>
								</el-table-column>
								<el-table-column prop="batchOpen" label="批开" align="center" width="60">
									<template #default="scope">
										<span v-if="scope.row.batchOpen==true">是</span>
										<span v-if="scope.row.batchOpen==false">否</span>
									</template>
								</el-table-column>
								<el-table-column prop="createTime" label="入库时间" align="center"
									width="160"></el-table-column>
								<el-table-column prop="batchNum" label="批次号" align="center"></el-table-column>
								<el-table-column prop="operator" label="操作人" align="center"></el-table-column>
							</el-table>
						</el-col>
					</el-row>
					<div class="pagination">
						<el-pagination background layout="total, sizes, prev, pager, next, jumper"
							:current-page="query.pageIndex" :page-sizes="[30, 100, 200, 500]"
							:page-size="query.pageSize" :total="pageTotal" @size-change="handleSizeChange"
							@current-change="handlePageChange"></el-pagination>
					</div>
				</div>
				<el-dialog title="入库" v-model="storeInventoryVisible" width="700px" :close-on-click-modal="false">
					<el-form :model="uploadData" ref="uploadData" label-width="120px">
						<el-form-item label="类型">
							<el-radio-group v-model="uploadData.type">
								<el-radio label="1">运营商提卡</el-radio>
								<el-radio label="2">小批量入库</el-radio>
								<el-radio label="3">退卡</el-radio>
							</el-radio-group>
						</el-form-item>
						<el-form-item label="品牌">
							<el-select size="small" v-model="uploadData.cardId" clearable filterable placeholder="请选择品牌"
								style="width: 100%;" @change="getCardId">
								<el-option v-for="item in cardTypeList" :key="item.label" :label="item.value"
									:value="item.label"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="套餐" v-if="uploadData.cardId">
							<el-select size="small" v-model="uploadData.setMealId" clearable filterable
								placeholder="请选择套餐" style="width: 100%;">
								<el-option v-for="item in setMealIdList" :key="item.label" :label="item.name"
									:value="item.id"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item v-if="uploadData.setMealId">
							<div v-for="item in setMealIdList" :key="item.id">
								<div v-if="uploadData.setMealId==item.id">
									<div v-for="items in item.setMealList" :key="items.id">
										{{items.min}}分钟{{items.price}}元
									</div>
								</div>
							</div>
						</el-form-item>
						<el-form-item label="是否批开">
							<el-select size="small" v-model="uploadData.batchOpen" clearable filterable
								placeholder="请选择" style="width: 100%;">
								<el-option v-for="item in batchOpenList" :key="item.label" :label="item.label"
									:value="item.value"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="添加号码" v-if="uploadData.type==1">
							<el-upload ref="upload" action="/api/SjtxCard/import" :headers="token" :limit="1"
								:on-success="uploadSuccess" :on-preview="handlePreview" :on-remove="handleRemove"
								:data="uploadData" :auto-upload="false">
								<el-button size="small" type="primary" :class="[theme]"
									style="margin-top: 40px;">选择文件</el-button>
								<div class="el-upload__tip">只能导入excel文件</div>
							</el-upload>
						</el-form-item>
						<el-form-item label="添加号码" v-if="uploadData.type==2">
							<el-input size="small" type="textarea" :rows="5" placeholder="一行一个号码,一次最多添加100个号码"
								v-model="uploadData.phones"></el-input>
						</el-form-item>
						<el-form-item label="添加号码" v-if="uploadData.type==3">
							<el-input size="small" type="textarea" :rows="5" placeholder="一行一个号码,一次最多添加100个号码"
								v-model="uploadData.phones"></el-input>
						</el-form-item>

						<el-form-item style="display: flex;">
							<div style="line-height: 30px;">1、严格按照预设字段填写，错误的位置要有报错提示。</div>
							<div style="line-height: 30px;">2、退卡号码要先去库存查询该号码是否存在，是否是我司号码。</div>
							<div style="line-height: 30px;">3、退卡再入库的号码要先判断号码状态 (已激活、已销户的号码都不能再入库)。</div>
							<div style="line-height: 30px;">4、退卡再入库的号码只需要填写号码即可自动补充其他信息(从库存表获取)。</div>
							<div style="line-height: 30px;">5、退卡再入库的号码，该号码历史出库记录自动删除。</div>
							<div style="line-height: 30px;">6、针对一批特殊号码能够快速的修改号码参数(比如库存里的成卡部分号码需要改为批开号码）。</div>
							<div style="line-height: 30px;">7、号码入库-出库-退卡-入库-出库-销户，一个号码的生命周期要有完整的日志记录。</div>
						</el-form-item>
						<el-form-item style="display: flex;flex-direction: row;justify-content: end;">
							<el-button size="small" @click="storeInventoryClose">取消</el-button>
							<el-button size="small" type="primary" :class="[theme]" @click="getImport">确定</el-button>
						</el-form-item>
					</el-form>
				</el-dialog>
				<el-dialog v-model="filterBox" width="250px" title="重复数据">
					<div class="filter-box" style="width: 200px;height: 400px;overflow: scroll;">
						<div v-for="item in filterListData" :key="item">{{ item }}</div>
					</div>
				</el-dialog>
				<el-dialog title="号码出库" v-model="outBoundVisible" width="500px" :close-on-click-modal="false">
					<el-form :model="outBoundForm" ref="outBoundForm" label-width="120px">
						<el-form-item label="代理" prop="agentId">
							<el-select v-model="outBoundForm.agentId" @change="agentChange" clearable filterable
								placeholder="请选择代理商" size="small" style="width: 100%;">
								<el-option v-for="item in agents" :key="item.value" :label="item.label"
									:value="item.value"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="企业" prop="companyId">
							<el-select v-model="outBoundForm.companyId" clearable filterable placeholder="请选择企业"
								size="small" style="width: 100%;" @change="getAddress">
								<el-option v-for="item in companys" :key="item.value" :label="item.label"
									:value="item.value"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="收件地址" prop="address">
							<el-select v-model="outBoundForm.address" clearable filterable allow-create
								default-first-option placeholder="请填写收件地址" size="small" style="width: 100%;">
								<el-option v-for="item in addressList" :key="item.address" :label="item.address"
									:value="item.address"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="收件人姓名" prop="consignee">
							<el-input size="small" type="text" placeholder="请输入收件人姓名"
								v-model="outBoundForm.consignee"></el-input>
						</el-form-item>
						<el-form-item label="收件人电话" prop="consigneePhone">
							<el-input size="small" type="text" placeholder="请输入电话号码"
								v-model="outBoundForm.consigneePhone"></el-input>
						</el-form-item>
						<el-form-item label="类型" prop="address">
							<el-radio-group v-model="outBoundForm.type">
								<el-radio label="1">新增</el-radio>
								<el-radio label="2">换卡</el-radio>
								<el-radio label="3">补卡</el-radio>
							</el-radio-group>
						</el-form-item>
						<el-form-item label="绿名单" prop="isReport">
							<el-radio-group v-model="outBoundForm.isReport">
								<el-radio :label="true">是</el-radio>
								<el-radio :label="false">否</el-radio>
							</el-radio-group>
						</el-form-item>
						<el-form-item label="快递单号" prop="logistics">
							<el-input size="small" type="text" placeholder="请填写快递单号"
								v-model="outBoundForm.logistics"></el-input>
						</el-form-item>
						<el-form-item label="出库号码" prop="phones">
							<el-input size="small" type="textarea" :rows="5" placeholder="出库号码 (一行一个号码)"
								v-model="outBoundForm.phones"></el-input>
						</el-form-item>
						<el-form-item style="display: flex;flex-direction: row;justify-content: end;">
							<el-button size="small" @click="outBoundClose">取消</el-button>
							<el-button size="small" type="primary" :class="[theme]" @click="getDelivery">确定</el-button>
						</el-form-item>
					</el-form>
				</el-dialog>
			</div>

		</el-tab-pane>
		<el-tab-pane :label="'预警列表'+'  '+'('+pageTotal2+')'" name="earlyWarning">
			<div>
				<div class="container">
					<div class="handle-box">
						<el-select size="small" v-model="query2.cardId" filterable clearable placeholder="请选择品牌"
							class="mr10" @change="getCardId" style="width: 140px;">
							<el-option v-for="item in cardTypeList" :key="item.label" :label="item.value"
								:value="item.label"></el-option>
						</el-select>
						<el-button size="small" type="primary" :class="[theme]" icon="el-icon-search" @click="getData2"
							style="margin-top: 10px;">搜索</el-button>
						<el-button size="small" type="primary" :class="[theme]" @click="exportData">导出</el-button>
					</div>
					<el-row :gutter="20" type="flex" class="row-bg">
						<el-col :span="24">
							<el-table :data="tableData2" border class="table" ref="multipleTable"
								header-cell-class-name="table-header" :row-style="{ height: '20px' }"
								:cell-style="{ padding: '6px' }" :header-cell-style="{ 'text-align': 'center' }"
								@selection-change="handleSelectionChange" :row-class-name="tableRowClassName"
								@row-click="showExpressDialog">
								<el-table-column prop="cardName" label="品牌" align="center"></el-table-column>
								<el-table-column prop="city" label="城市" align="center"></el-table-column>
								<el-table-column prop="count" label="总数" align="center"></el-table-column>
								<el-table-column prop="num" label="预警数量" align="center"></el-table-column>
							</el-table>
						</el-col>
					</el-row>
					<div class="pagination">
						<el-pagination background layout="total, sizes, prev, pager, next, jumper"
							:current-page="query2.pageIndex" :page-sizes="[30, 100, 200, 500]"
							:page-size="query2.pageSize" :total="pageTotal2" @size-change="handleSizeChange2"
							@current-change="handlePageChange2"></el-pagination>
					</div>
				</div>
			</div>
		</el-tab-pane>
	</el-tabs>
</template>

<script>
	import {
		getReportNumberList,
		deleteReportNumber,
		getReportCompany,
		getReportAgent,
		downloadReportNumber
	} from '../api/reportNumber.js';
	import { SJSZInventorystatistics } from '../api/setMeal.js';
	import { getCardType } from '../api/cardType.js';
	import { addReport, fetchCompanyByAgentId } from '../api/companyIndex.js';
	import {
		InventoryCardList,
		setMealList,
		getImport,
		delivery,
		InventoryByProvince,
		companyAddress,
		forewarningList,
		forewarningAdd,
		forewarningDelete,
		forewarningUpdate,
		QueryForewarning,
		downloadForewarning,
		InventoryProvinceCity,
		InventoryProvince,
		InventoryCity,
		InventoryInfo
	} from '../api/SjtxCard.js';
	import { fetchAgent } from '../api/agentIndex';
	export default {
		name: 'inventoryList',
		data() {
			return {
				activeName: 'inventoryList',
				theme: localStorage.getItem('theme'),
				query: {
					phone: '',
					cardId: '',
					setMealId: '',
					standard: '',
					batchNum: '',
					batchOpen: '',
					province: '',
					city: '',
					startTime: '',
					endTime: '',
					pageIndex: 1,
					pageSize: 30,
				},
				timeArr: [],
				cardIdList: [],
				setMealIdList: [],
				standardList: [{
						value: '1',
						label: '联通',
					},
					{
						value: '2',
						label: '电信',
					},
					{
						value: '3',
						label: '移动',
					}
				],
				batchOpenList: [{
						value: true,
						label: '是',
					},
					{
						value: false,
						label: '否',
					}
				],
				tableData: [],
				pageTotal: 0,
				multipleSelection: [],
				outBoundForm: {
					agentId: '',
					companyId: '',
					phones: '',
					address: '',
					consignee: '',
					userId: localStorage.getItem('user'),
					consigneePhone: '',
					type: '1',
					isReport: true,
					logistics: ''
				},
				filterBox: false,
				filterListData: [],

				uploadData: {
					type: '1',
					setMealId: '',
					batchOpen: '',
					cardId: '',
					userId: localStorage.getItem('user')
				},
				path: process.env.BASE_URL,
				storeInventoryVisible: false,
				outBoundVisible: false,
				cityList: [],
				cityList2: [],
				provinceList2: [],
				provinceList: [],
				inventoryInfoList: [],
				cardTypeList: [],
				token: {
					Authorization: localStorage.getItem('token'),
				},
				agents: [],
				companys: [],
				addressList: [],
				query2: {
					cardId: '',
					pageIndex: 1,
					pageSize: 10,
				},
				tableData2: [],
				pageTotal2: 0,
				showForm: false
			};
		},
		created() {
			this.getTime();
			getCardType('').then(res => {
				this.cardTypeList = res.data;
			})
			this.getData();
			// this.getSJSZInventoryStatistics();
			this.getInventoryProvince();
			this.getInventoryCity();
			this.getInventoryInfo();
			this.getCardId();
			fetchAgent(localStorage.getItem('user')).then(res => {
				//代理
				this.agents = res.data;
			});
			this.getData2();
		},
		methods: {
			handleClick(tab, event) {
				if (tab.props.label == '库存列表') {
					this.getData();
				} else {
					// this.query2.cardId = this.cardTypeList[0].label
					this.getData2();
				}
			},
			//获取时间
			getTime() {
				let startTime = new Date(new Date());
				let endTime = new Date();
				this.timeArr.push(startTime);
				this.timeArr.push(endTime);
			},
			tableRowClassName({ rowIndex }) {
				if ((rowIndex + 1) % 2 === 0) {
					return 'oddRow';
				}
				return 'evenRow';
			},
			getquery() {
				this.getInventoryProvince();
				this.getInventoryCity();
				this.getInventoryInfo();
				this.query.province = '';
				this.query.city = '';
			},
			//库存列表
			getInventoryInfo() {
				this.inventoryInfoList = [];
				let data = {
					province: this.query.province,
					city: this.query.city,
					standard: this.query.standard,
					cardId: this.query.cardId,
					setMealId: this.query.setMealId,
				};
				InventoryInfo(data).then(res => {
					if (res.code == 200) {
						this.inventoryInfoList = res.data;
					} else {
						this.$message.error(res.message);
					}
				});
			},
			//获取品牌和品牌里面的套餐
			getCardId() {
				this.getquery();
				this.query.setMealId = '';
				this.setMealIdList = [];
				if (this.uploadData.cardId) {
					let data = {
						cardId: this.uploadData.cardId,
					};
					setMealList(data).then(res => {
						if (res.code == 200) {
							this.setMealIdList = res.data;
						} else {
							this.$message.error(res.message);
						}
					});
				} else if (this.query.cardId) {
					let data = {
						cardId: this.query.cardId,
					};
					setMealList(data).then(res => {
						if (res.code == 200) {
							this.setMealIdList = res.data;
						} else {
							this.$message.error(res.message);
						}
					});
				} else {
					let data = {
						cardId: '',
					};
					setMealList(data).then(res => {
						if (res.code == 200) {
							this.setMealIdList = res.data;
						} else {
							this.$message.error(res.message);
						}
					});
				}
			},
			//快递详情
			getAddress() {
				let data = {
					companyId: this.outBoundForm.companyId
				}
				companyAddress(data).then(res => {
					if (res.code == 200) {
						this.addressList = res.data;
					} else {
						this.$message.error(res.message);
					}
				});
			},
			//获取代理商
			agentChange(agentId) {
				this.outBoundForm.agentId = agentId;
				this.outBoundForm.companyId = '';
				//代理商下拉
				if (agentId) {
					let data = {
						subAgent: JSON.parse(localStorage.getItem('subAgent').toLowerCase()),
						agentId: agentId
					};
					fetchCompanyByAgentId(data).then(res => {
						this.companys = res.data;
					});
				}
			},
			getData() {
				let data = {
					phone: this.query.phone,
					cardId: this.query.cardId,
					setMealId: this.query.setMealId,
					province: this.query.province,
					city: this.query.city,
					status: this.query.status,
					standard: this.query.standard,
					batchNum: this.query.batchNum,
					batchOpen: this.query.batchOpen,
					startTime: this.timeArr[1],
					endTime: this.query[2],
					pageSize: this.query.pageSize,
					pageIndex: this.query.pageIndex
				};
				InventoryCardList(data).then(res => {
					if (res.code == 200) {
						this.tableData = res.data.list;
						this.pageTotal = res.data.total;
					} else {
						this.$message.error(res.message);
					}
				});
			},

			//出库弹窗
			outBound() {
				this.outBoundVisible = true;
			},
			//出库
			getDelivery() {
				delivery(this.outBoundForm).then(res => {
					if (res.code == 200) {
						this.outBoundVisible = false;
						this.getData();
					} else {
						this.$message.error(res.message);
					}
				});
			},
			//关闭出库弹窗
			outBoundClose() {
				this.outBoundVisible = false;
			},
			//入库
			storeInventory() {
				this.storeInventoryVisible = true;
				this.uploadData = {
					type: '1',
					userId: localStorage.getItem('user')
				}
			},
			//入库上传
			getImport() {
				if (this.$refs.upload.uploadFiles[0]) {
					this.$refs.upload.submit();
				} else {
					this.$message.error('请选择上传文件');
				}
			},
			//导入上传
			uploadSuccess(success) {
				if (success.code == 200) {
					this.$message.success(success.message);
					this.getData();
					this.addVisible = false;
				} else {
					if (success.message == '号码已存在') {
						this.filterBox = true;
						this.filterListData = success.data
						this.$refs.upload.clearFiles();
					} else {
						this.$refs.upload.clearFiles();
						this.$message.error(success.message);
					}
				}
			},

			handleRemove(file, fileList) {
				console.log(file, fileList);
			},
			handlePreview(file) {
				console.log(file);
			},
			//关闭入库弹窗
			storeInventoryClose() {
				this.storeInventoryVisible = false;
			},
			// 多选操作
			handleSelectionChange(val) {
				this.multipleSelection = val;
			},

			// 分页导航
			handlePageChange(val) {
				this.query.pageIndex = val;
				this.getData();
			},
			//下一页
			handleSizeChange(val) {
				this.query.pageSize = val;
				this.getData();
			}, // 点击省
			tabProvince(item) {
				this.query.province = item.province;
				this.query.city = '';
				this.getInventoryInfo();
			},

			// //获取库存城市列表
			getSJSZInventoryStatistics() {
				let data = {
					province: this.query.province,
					city: this.query.city,
					standard: this.query.standard,
					cardId: this.query.cardId,
					setMealId: this.query.setMealId,
				};
				InventoryProvinceCity(data).then(res => {
					if (res.code == 200) {
						this.provinceList = res.data.province;
						this.cityList = res.data.city;
					} else {
						this.$message.error(res.message);
					}
				});
			},
			//省份列表
			getProvinceList() {
				let data = {
					city: this.query.city,
					province: this.query.province,
					standard: this.query.standard,
					cardId: this.query.cardId,
					setMealId: this.query.setMealId,
				};
				InventoryProvinceCity(data).then(res => {
					if (res.code == 200) {
						this.provinceList = res.data.province;
						this.cityList = res.data.city;
					} else {
						this.$message.error(res.message);
					}
				});
			},
			//省份列表
			getInventoryProvince() {
				let data = {
					standard: this.query.standard,
					cardId: this.query.cardId,
					setMealId: this.query.setMealId,
				};
				InventoryProvince(data).then(res => {
					if (res.code == 200) {
						this.provinceList = res.data;
					} else {
						this.$message.error(res.message);
					}
				});
			},
			//城市列表
			getInventoryCity() {
				this.query.city = '';
				let data = {
					province: this.query.province,
					standard: this.query.standard,
					cardId: this.query.cardId,
					setMealId: this.query.setMealId,
				};
				InventoryCity(data).then(res => {
					if (res.code == 200) {
						this.cityList = res.data;
						if (this.query.province) {
							this.getInventoryInfo()
						}
					} else {
						this.$message.error(res.message);
					}
				});
			},
			//获取预警列表
			getData2() {
				this.tableData2 = []
				QueryForewarning(this.query2).then(res => {
					if (res.code == 200) {
						this.tableData2 = res.data.list;
						this.pageTotal2 = res.data.total;
					} else {
						this.$message.error(res.message);
					}
				});
			},
			// 分页导航
			handlePageChange2(val) {
				this.query2.pageIndex = val;
				this.getData2();
			},
			//下一页
			handleSizeChange2(val) {
				this.query2.pageSize = val;
				this.getData2();
			}, // 分页导航

			exportData() {
				downloadForewarning(this.query2).then(res => {
					this.$message.success('导出中');
					const blob = new Blob([res], {
						type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
					});
					let downloadElement = document.createElement('a');
					let href = window.URL.createObjectURL(blob);
					downloadElement.href = href;
					let fileName = '预警数据' + '.xlsx';
					downloadElement.download = fileName;
					document.body.appendChild(downloadElement);
					downloadElement.click();
					document.body.removeChild(downloadElement);
					window.URL.revokeObjectURL(href);
				});
			},

		}
	};
</script>

<style scoped>
	.handle-box {
		margin-bottom: 50px;
	}

	.city-box {
		margin: 20px 0;
	}

	.city-box div {
		width: 140px;
		float: left;
		margin-right: 20px;
	}

	.city-box div span {
		margin-right: 6px;
	}

	/* a:hover{background: #66b1ff} */
	.handle-select {
		width: 240px;
	}

	.table {
		width: 100%;
		font-size: 12px;
	}

	.mr10 {
		margin-right: 10px;
	}

	.mt10 {
		margin-top: 10px;
	}

	.customer .el-upload {
		display: flex !important;
		flex-direction: column !important;
		align-items: center !important;
		justify-content: center !important;
		width: 100% !important;
		line-height: 50px !important;
		padding: 30px 0 !important;
	}


	.loyo-folding-btn:hover {
		border-color: #2c9dfc;
	}

	.loyo-folding-btn {
		position: relative;
		color: #2c9dfc;
		height: 20px;
		text-align: center;
		border-top: 1px solid #e5e9f0;
		margin-top: 15px;
		cursor: pointer;
	}

	.loyo-folding-btn a {
		position: absolute;
		bottom: -1px;
		border-bottom: 1px solid #ddd;
		border-left: 1px solid #ddd;
		border-right: 1px solid #ddd;
		border-top: 3px solid #ddd;
		border-top-color: #fff;
		width: 60px;
		height: 20px;
		line-height: 20px;
	}

	.loyo-folding-btn a i {
		font-size: 18px;
		color: #ddd;
	}

	.loyo-folding-btn:hover i {
		color: #2c9dfc;
	}

	.loyo-folding-btn:hover a {
		border-bottom: 1px solid #2c9dfc;
		border-left: 1px solid #2c9dfc;
		border-right: 1px solid #2c9dfc;
		border-top: 3px solid #2c9dfc;
		border-top-color: #fff;
	}
</style>